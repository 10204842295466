import { DateTime } from "luxon";
import React from "react";

interface IAgoTimeProps {
  date: DateTime;
  inline?: boolean;
}

export default function AgoTime(props: IAgoTimeProps) {
  const { date, inline } = props;
  const now = DateTime.now();

  let formatted;

  if (date.toISODate() === now.toISODate() && !inline) {
    // Today
    formatted = date.toLocaleString(DateTime.TIME_SIMPLE);
    if (inline) formatted = "at " + formatted;
  } else if (
    DateTime.fromISO(date.toISODate()).valueOf() >=
    DateTime.fromISO(DateTime.now().toISODate()).minus({ days: 1 }).valueOf()
  ) {
    // Yesterday
    formatted =
      date.toRelativeCalendar() +
      " at " +
      date.toLocaleString(DateTime.TIME_SIMPLE);
    if (!inline)
      formatted = formatted[0].toUpperCase() + formatted.substring(1);
  } else {
    formatted = date.toLocaleString(DateTime.DATE_SHORT);
    if (inline) formatted = "on " + formatted;
  }

  return <>{formatted}</>;
}
