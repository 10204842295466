import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getCurrentUser,
  getTopics,
  subscribeTopic,
  unsubscribeTopic,
} from "../../api/api";
import { RootState } from "../../state/store";
import { TopicsState } from "../../state/topicsReducer";

export default function Topics() {
  const history = useHistory();

  useEffect(() => {
    getTopics(history);
    getCurrentUser(history);
  }, [history]);

  const topics = useSelector<RootState, TopicsState["topics"]>(
    (state) => state.topics.topics
  );

  const subscribed = useSelector<RootState, TopicsState["subscribed"]>(
    (state) => state.topics.subscribed
  );

  const setTopicSubscribed = async (topic: string, enabled: boolean) => {
    if (enabled) await subscribeTopic(history, topic);
    else await unsubscribeTopic(history, topic);
  };

  return (
    <Container className="py-3 pt-5">
      <h2 className="h1">Topics</h2>
      <ListGroup>
        {Object.values(topics).map((topic) => {
          const enabled = subscribed.has(topic.id);
          return (
            <ListGroup.Item key={topic.id}>
              {topic.name}
              <Button
                variant={enabled ? "primary" : "secondary"}
                className="float-right"
                onClick={() => setTopicSubscribed(topic.id, !enabled)}
              >
                {enabled ? "Enabled" : "Disabled"}
              </Button>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    </Container>
  );
}
