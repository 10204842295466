import axios, { AxiosInstance } from "axios";
import { History } from "history";
import { DateTime } from "luxon";
import { toast } from "react-hot-toast";
import { IBaseError } from "../types";

export interface APIModel<T extends APIModel<T>> {
  _previousDataValues?: Partial<T>;
  raw: typeof raw;
  error: false;
  id: string;
  updatedAt: DateTime;
  createdAt: DateTime;
}

export enum ErrorCode {
  USER_NOT_FOUND,
  TOPIC_NOT_FOUND,
  ISSUE_NOT_FOUND,
  INVALID_FIND_OPTION,
  INVALID_LOGIN,
  UNAUTHORIZED,
  FORBIDDEN,
  ISSUE_CLOSED,
  INTERNAL_ERROR,
  UNCAUGHT_EXCEPTION,
}

const base = process.env.REACT_APP_REST_API_URL;

const instance = axios.create({
  baseURL: base,
  withCredentials: true,
});

export const login = (history?: History) => {
  if (history) history.push("/login");
};

const requiresReauthentication = (error: any) => {
  const status = error?.response?.status;
  return status === 401 || 403;
};

export const isAxiosErrorEddyBaseError = (
  error: any
): IBaseError | undefined => {
  return Boolean(error?.response?.data?.error && error?.response?.data?.code)
    ? (error.response.data as IBaseError)
    : error.error && error.code
    ? (error as IBaseError)
    : void 0;
};

const handleError = (error: any, history?: History) => {
  let baseError;
  if ((baseError = isAxiosErrorEddyBaseError(error))) {
    if (requiresReauthentication(error)) login(history);
    handlerBaseErrorToast(baseError);
    throw baseError;
  }
  throw error;
};

const handlerBaseErrorToast = (error: IBaseError) => {
  if (isBaseErrorOfCode(error, ErrorCode.UNAUTHORIZED)) return;
  toast.error(error.message || error.code);
};

export const isBaseErrorOfCode = (
  error: IBaseError | undefined,
  code: ErrorCode
) => {
  return error?.code === ErrorCode[code].toLowerCase();
};

export const callGet = async (
  history?: History,
  ...args: Parameters<AxiosInstance["get"]>
) => {
  try {
    return (await instance.get(...args)).data;
  } catch (error) {
    handleError(error, history);
  }
};

export const callPatch = async (
  history: History,
  ...args: Parameters<AxiosInstance["patch"]>
) => {
  try {
    return (await instance.patch(...args)).data;
  } catch (error) {
    handleError(error, history);
  }
};

export const callDelete = async (
  history: History,
  ...args: Parameters<AxiosInstance["delete"]>
) => {
  try {
    return (await instance.delete(...args)).data;
  } catch (error) {
    handleError(error, history);
  }
};

export const callPost = async (
  history: History,
  ...args: Parameters<AxiosInstance["post"]>
) => {
  try {
    return (await instance.post(...args)).data;
  } catch (error) {
    handleError(error, history);
  }
};

export const callPut = async (
  history: History,
  ...args: Parameters<AxiosInstance["put"]>
) => {
  try {
    return (await instance.put(...args)).data;
  } catch (error) {
    handleError(error, history);
  }
};

const raw = function <T extends APIModel<T>>(instance: T) {
  const obj: Partial<T> = {};
  for (const k of Object.keys(instance)) {
    const key = k as keyof typeof instance;
    const value = instance[key];
    if (typeof value === "object" || typeof value === "function") continue;
    obj[key] = value;
  }
  return obj;
};

export const buildModel = <T extends APIModel<T>>(rawObj: T) => {
  delete rawObj._previousDataValues;
  rawObj._previousDataValues = raw(rawObj);
  rawObj.updatedAt =
    typeof rawObj.updatedAt === "string"
      ? DateTime.fromISO(rawObj.updatedAt)
      : rawObj.updatedAt;
  rawObj.createdAt =
    typeof rawObj.createdAt === "string"
      ? DateTime.fromISO(rawObj.createdAt)
      : rawObj.updatedAt;
  return rawObj;
};

export const isModelValid = <T extends APIModel<T>>(model: APIModel<T>) => {
  if (
    !(model.updatedAt instanceof DateTime) ||
    !model.updatedAt.isValid ||
    !(model.createdAt instanceof DateTime) ||
    !model.createdAt.isValid
  )
    return false;
  return true;
};
