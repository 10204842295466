import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { LinkContainer } from "react-router-bootstrap";
import { useHistory } from "react-router-dom";
import { logout } from "../../api/api";
import { useCurrentUser } from "../../api/stateKeeper";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import "../../assets/style/header.scss";

export default function Header() {
  const history = useHistory();

  const user = useCurrentUser();

  const avatarUrl = `https://picsum.photos/seed/${user?.id}/512/512`;

  const handleLogout = () => logout(history);

  return (
    <Navbar bg="white" expand={true} className="py-3 header flex-shrink-0">
      <Container>
        <LinkContainer to={user ? "/" : "/login"} exact>
          <Logo className="d-inline-block align-top cursor-pointer" />
        </LinkContainer>
        <Nav className="ml-auto">
          {user && (
            <NavDropdown
              className="avatar-dropdown"
              alignRight={true}
              title={
                <>
                  <span className="username">{user?.username}</span>
                  <img
                    className="avatar"
                    alt={user?.username}
                    src={avatarUrl}
                  />
                </>
              }
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
            </NavDropdown>
          )}
        </Nav>
      </Container>
    </Navbar>
  );
}
