import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { GatewayStatusState } from "../state/gatewayStatusReducer";
import { NormalizedStore, RootState } from "../state/store";
import { TopicsState } from "../state/topicsReducer";
import { UsersState } from "../state/usersReducer";
import { ITopic, IUser } from "../types";
import { getTopic, getUser } from "./api";

const usersLoading: Set<string> = new Set();
const topicsLoading: Set<string> = new Set();

export const useUsers = (
  userIds: Array<string | undefined>
): NormalizedStore<IUser | undefined> => {
  const history = useHistory();

  const users = useSelector<RootState, UsersState["users"]>(
    (state) => state.users.users
  );

  for (const userId of userIds) {
    if (!userId) continue;
    if (users[userId]) continue;
    if (usersLoading.has(userId)) continue;

    usersLoading.add(userId);
    getUser(history, userId)
      .then(() => usersLoading.delete(userId))
      .catch(() => usersLoading.delete(userId));
  }

  return users;
};

export const useUser = (userId: string | undefined) => {
  const users = useUsers([userId]);
  return userId ? users[userId] : void 0;
};

export const useCurrentUser = () => {
  const userId = useSelector<RootState, GatewayStatusState["user"]>(
    (state) => state.gatewayStatus.user
  );

  return useUser(userId);
};

export const useTopics = (
  topicIds: Array<string | undefined>
): NormalizedStore<ITopic | undefined> => {
  const history = useHistory();

  const topics = useSelector<RootState, TopicsState["topics"]>(
    (state) => state.topics.topics
  );

  for (const topicId of topicIds) {
    if (!topicId) continue;
    if (topics[topicId]) continue;
    if (topicsLoading.has(topicId)) continue;

    topicsLoading.add(topicId);
    getTopic(history, topicId)
      .then(() => topicsLoading.delete(topicId))
      .catch(() => topicsLoading.delete(topicId));
  }

  return topics;
};

export const useTopic = (topicId: string | undefined) => {
  const topics = useTopics([topicId]);
  return topicId ? topics[topicId] : void 0;
};
