import React from "react";
import { ReactComponent as Logo } from "../../assets/images/logo_white.svg";
import "../../assets/style/loading.scss";

export default function Loading() {
  return (
    <div className="loading-content">
      <div className="spinner">
        <div className="double-bounce1"></div>
        <div className="double-bounce2"></div>
      </div>
      <div className="brand">
        <Logo />
      </div>
    </div>
  );
}
