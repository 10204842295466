import { History } from "history";
import store from "../state/store";
import { IAuth, IIssue, IIssueMessage, ITopic, IUser } from "../types";
import {
  callDelete,
  callGet,
  callPost,
  callPut,
  ErrorCode,
  isAxiosErrorEddyBaseError,
  isBaseErrorOfCode,
} from "./apiBase";
import { connect, disconnect } from "./gateway";
import {
  normalizeAndStoreCurrentUser,
  normalizeAndStoreIssue,
  normalizeAndStoreIssues,
  normalizeAndStoreMessage,
  normalizeAndStoreMessages,
  normalizeAndStoreTopic,
  normalizeAndStoreTopics,
  normalizeAndStoreUser,
} from "./normalize";

/**
 * POST /auth/login
 */
export const login = async (
  history: History,
  login: string,
  password: string
) => {
  const res: IAuth = await callPost(history, `/auth/login`, {
    login,
    password,
  });
  if (res.success) connect();
  return res;
};

/**
 * POST /auth/logout
 */
export const logout = async (history: History) => {
  const res: IAuth = await callPost(history, `/auth/logout`);
  disconnect();
  return res;
};

/**
 * GET /users/@me
 */
export const getCurrentUser = async (history?: History) => {
  try {
    const res: IUser = await callGet(history, `/users/@me`);
    return normalizeAndStoreCurrentUser(res);
  } catch (e) {
    const error = isAxiosErrorEddyBaseError(e);
    if (isBaseErrorOfCode(error, ErrorCode.UNAUTHORIZED))
      store.dispatch({ type: "SET_CURRENT_USER", payload: void 0 });
  }
};

/**
 * GET /users/:userId
 */
export const getUser = async (history: History | undefined, userId: string) => {
  const res: IUser = await callGet(history, `/users/${userId}`);
  return normalizeAndStoreUser(res);
};

/**
 * PUT /users/@me/topics/:topicId
 */
export const subscribeTopic = async (history: History, topicId: string) => {
  await callPut(history, `/users/@me/topics/${topicId}`);
  // TODO: Check response
  store.dispatch({
    type: "SET_SUBSCRIBED",
    payload: new Set([
      ...Array.from(store.getState().topics.subscribed),
      topicId,
    ]),
  });
  return;
};

/**
 * DELETE /users/@me/topics/:topicId
 */
export const unsubscribeTopic = async (history: History, topicId: string) => {
  await callDelete(history, `/users/@me/topics/${topicId}`);
  // TODO: Check response
  store.dispatch({
    type: "SET_SUBSCRIBED",
    payload: new Set(
      Array.from(store.getState().topics.subscribed).filter(
        (topic) => topic !== topicId
      )
    ),
  });
  return;
};

/**
 * GET /topics
 */
export const getTopics = async (history: History) => {
  const res: Array<ITopic> = await callGet(history, `/topics`);
  return normalizeAndStoreTopics(res);
};

/**
 * GET /topics/:topicId
 */
export const getTopic = async (history: History, topicId: string) => {
  const res: ITopic = await callGet(history, `/topics/${topicId}`);
  return normalizeAndStoreTopic(res);
};

/**
 * GET /issues
 */
export const getIssues = async (history?: History, closed = false) => {
  const res: Array<IIssue> = await callGet(history, `/issues`, {
    params: { closed },
  });
  return normalizeAndStoreIssues(res);
};

/**
 * GET /issues
 */
export const createIssue = async (
  history: History,
  topicId: string,
  title: string
) => {
  const res: IIssue = await callPost(history, `/issues`, {
    topic: topicId,
    title,
  });
  return normalizeAndStoreIssue(res);
};

/**
 * GET /issues/:issueId
 */
export const getIssue = async (history: History, issueId: string) => {
  const res: IIssue = await callGet(history, `/issues/${issueId}`);
  return normalizeAndStoreIssue(res);
};

/**
 * DELETE /issues/:issue
 */
export const closeIssue = async (history: History, issueId: string) => {
  const res: IIssue = await callDelete(history, `/issues/${issueId}`);
  return normalizeAndStoreIssue(res);
};

/**
 * POST /issues/:issue/assign
 */
export const assignIssue = async (history: History, issueId: string) => {
  const res: IIssue = await callPost(history, `/issues/${issueId}/assign`);
  return normalizeAndStoreIssue(res);
};

/**
 * GET /issues/:issueId/messages
 */
export const getIssueMessages = async (history: History, issueId: string) => {
  // TODO: Pagination
  const res: Array<IIssueMessage> = await callGet(
    history,
    `/issues/${issueId}/messages`
  );
  return normalizeAndStoreMessages(res);
};

/**
 * GET /issues/:issueId/messages
 */
export const sendIssueMessage = async (
  history: History,
  issueId: string,
  content: string
) => {
  // TODO: Pagination
  const res: IIssueMessage = await callPost(
    history,
    `/issues/${issueId}/messages`,
    { content }
  );
  return normalizeAndStoreMessage(res);
};
