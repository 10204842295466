import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { useUser } from "../../api/stateKeeper";
import "../../assets/style/header.scss";
import { IIssue } from "../../types";
import AgoTime from "./AgoTime";
import IssueBadges from "./IssueBadges";

interface IIssueOverviewProps {
  issue?: IIssue;
}

export default function IssueOverview(props: IIssueOverviewProps) {
  const { issue } = props;

  const author = useUser(issue?.authorId);

  return (
    <Navbar
      bg="white"
      expand="lg"
      className="py-2 issue-overview flex-shrink-0"
    >
      <Container>
        <Navbar.Brand className="d-flex flex-column">
          <span className="issue-title">{issue?.title}</span>
          <span className="topics">
            <IssueBadges issue={issue} />
          </span>
        </Navbar.Brand>
        <Navbar.Text>
          Asked by {author?.username}{" "}
          {issue && <AgoTime date={issue.createdAt} inline />}
        </Navbar.Text>
      </Container>
    </Navbar>
  );
}
