import React from "react";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./assets/style/style.scss";
import Home from "./components/Home";
import Login from "./components/Login";
import Header from "./components/partials/Header";
import ProtectedArea from "./components/partials/ProtectedArea";
import ViewIssue from "./components/ViewIssue";
import store from "./state/store";

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ProtectedArea>
          <Header />
          <Switch>
            <Route path="/login" exact>
              <Login />
            </Route>
            <Route path="/issues/:issueId" exact>
              <ViewIssue />
            </Route>
            <Route path="/" exact>
              <Home />
            </Route>
          </Switch>
          <Toaster toastOptions={{ duration: 5000 }} />
        </ProtectedArea>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
