import { IIssue, IIssueMessage } from "../types";
import { NormalizedStore } from "./store";

// TODO: Make sure relations are kept when messages are update
export interface IssuesState {
  issues: NormalizedStore<IIssue>;
  messages: NormalizedStore<IIssueMessage>;
  showClosed: boolean;
}

export const initialState: IssuesState = {
  issues: {},
  messages: {},
  showClosed: false,
};

type Action =
  | { type: "SET_ISSUES"; payload: NormalizedStore<IIssue> }
  | { type: "UPDATE_ISSUES"; payload: NormalizedStore<IIssue> }
  | {
      type: "UPDATE_ISSUE_MESSAGES";
      payload: NormalizedStore<IIssueMessage>;
    }
  | {
      type: "SET_SHOW_CLOSED";
      payload: boolean;
    }
  | { type: "RESET_ISSUES" };

export default function issuesReducer(
  state = initialState,
  action: Action
): IssuesState {
  switch (action.type) {
    case "SET_ISSUES":
      return { ...state, issues: action.payload };
    case "UPDATE_ISSUES":
      return {
        ...state,
        issues: { ...state.issues, ...action.payload },
      };
    case "UPDATE_ISSUE_MESSAGES":
      return {
        ...state,
        messages: {
          ...state.messages,
          ...action.payload,
        },
      };
    case "SET_SHOW_CLOSED":
      return { ...state, showClosed: action.payload };
    case "RESET_ISSUES":
      return { ...state, issues: {} };
    default:
      return state;
  }
}
