import React, { useEffect, useRef } from "react";
import "../../assets/style/chatview.scss";
import { IIssueMessage } from "../../types";
import ChatMessage from "./ChatMessage";

interface IChatViewProps {
  messages: Array<IIssueMessage>;
}

export default function ChatView(props: IChatViewProps) {
  const { messages } = props;

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) ref.current.scrollIntoView({ behavior: "auto" });
  }, [messages]);

  return (
    <>
      <div className="chat-container pt-4">
        <ul className="chat-box chatContainerScroll">
          {Array.from(messages).map((message) => (
            <ChatMessage message={message} key={message.id} />
          ))}
        </ul>
      </div>
      <div ref={ref}></div>
    </>
  );
}
