import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getIssues } from "../../api/api";
import { useCurrentUser } from "../../api/stateKeeper";
import { IssuesState } from "../../state/issuesReducer";
import store, { RootState } from "../../state/store";
import ListIssue from "./ListIssue";

export default function Feed() {
  const history = useHistory();

  const user = useCurrentUser();

  const showClosed = useSelector<RootState, IssuesState["showClosed"]>(
    (state) => state.issues.showClosed
  );

  useEffect(() => {
    getIssues(history, showClosed);
  }, [history, showClosed]);

  const issues = useSelector<RootState, Array<IssuesState["issues"][""]>>(
    (state) =>
      Object.values(state.issues.issues)
        .filter((issue) => showClosed || !issue.closed)
        .filter((issue) =>
          user?.teacher
            ? !issue.teacherId || issue.teacherId === user?.id
            : issue.authorId === user?.id
        )
        .sort((a, b) => b.createdAt.valueOf() - a.createdAt.valueOf())
  );

  return (
    <Container className="py-3">
      <div className="d-flex">
        <div className="flex-grow-1">
          <h1>Feed</h1>
        </div>
        <div className="mt-auto mb-2">
          <Form.Check
            type="switch"
            id="feed-show-closed-switch"
            checked={showClosed}
            onChange={(e) =>
              store.dispatch({
                type: "SET_SHOW_CLOSED",
                payload: e.target.checked,
              })
            }
            label="Show Closed"
          />
        </div>
      </div>
      <ListGroup>
        {issues.map((issue) => (
          <ListIssue issue={issue} key={issue.id} />
        ))}
      </ListGroup>
    </Container>
  );
}
