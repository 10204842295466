import React, { FormEvent, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";

interface IChatTextBox {
  closed: boolean;
  handleSend: (content: string) => void;
  handleClose: () => void;
}

export default function ChatTextBox(props: IChatTextBox) {
  const { closed, handleSend, handleClose } = props;

  const [content, setContent] = useState("");

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    // Trim content
    const newContent = content.trim();
    if (!newContent) return setContent(newContent);

    // Send trimmed content
    handleSend(newContent);
    setContent("");
  };

  // TODO: Close button
  return (
    <div className="chat-text-box py-3 flex-shrink-0">
      <Container>
        <Form onSubmit={onSubmit} className="d-flex flex-row">
          {!closed && (
            <Button
              type="button"
              variant="danger"
              size="lg"
              className="mr-2"
              onClick={() => handleClose()}
            >
              Close
            </Button>
          )}
          <Form.Group
            controlId="chatTextBoxContent"
            className="mb-0 flex-grow-1"
          >
            <Form.Control
              type="text"
              placeholder={
                !closed ? "Send a message..." : "This question has been closed."
              }
              size="lg"
              disabled={closed}
              value={!closed ? content : ""}
              onChange={(e) => setContent(e.target.value)}
            />
          </Form.Group>
          {!closed && (
            <Button
              type="submit"
              variant="primary"
              size="lg"
              className="ml-2"
              disabled={!content.trim()}
            >
              Send
            </Button>
          )}
        </Form>
      </Container>
    </div>
  );
}
