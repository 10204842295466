import React from "react";
import { useSelector } from "react-redux";
import { GatewayStatusState } from "../state/gatewayStatusReducer";
import { RootState } from "../state/store";
import { UsersState } from "../state/usersReducer";
import CreateIssue from "./partials/CreateIssue";
import Feed from "./partials/Feed";
import ListIssues from "./partials/ListIssues";
import Topics from "./partials/Topics";

export default function Home() {
  const userId = useSelector<RootState, GatewayStatusState["user"]>(
    (state) => state.gatewayStatus.user
  );

  const user = useSelector<RootState, UsersState["users"][0] | null>((state) =>
    userId ? state.users.users[userId] : null
  );

  return (
    <main>
      {user?.teacher ? (
        <>
          <Topics />
          <Feed />
        </>
      ) : (
        <>
          <CreateIssue />
          <ListIssues />
        </>
      )}
    </main>
  );
}
