import React, { FormEvent, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { createIssue, getIssues, getTopics } from "../../api/api";
import { RootState } from "../../state/store";
import { TopicsState } from "../../state/topicsReducer";

export default function CreateIssue() {
  const history = useHistory();

  useEffect(() => {
    getIssues(history);
    getTopics(history);
  }, [history]);

  const topics = useSelector<RootState, TopicsState["topics"]>(
    (state) => state.topics.topics
  );

  const [content, setContent] = useState("");
  const [topicId, setTopicId] = useState("");

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    // Trim content
    const newContent = content.trim();
    // TODO: Validation
    if (!newContent) return setContent(newContent);

    // Send trimmed content
    createIssue(history, topicId, newContent).then((issue) => {
      history.push(`/issues/${issue?.id}`);
      toast.success("Question created!");
    });
    setContent("");
  };

  // TODO: Validation
  return (
    <Container className="py-3 pt-5">
      <h1>Ask a Question</h1>
      <Form onSubmit={onSubmit} className="d-flex flex-row">
        <Form.Group controlId="createIssueContext" className="mb-0 flex-grow-1">
          <Form.Control
            type="text"
            placeholder="Ask a question..."
            size="lg"
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="createIssueTopic" className="mb-0 mx-2">
          <Form.Control
            as="select"
            size="lg"
            value={topicId}
            onChange={(e) => setTopicId(e.target.value)}
            custom
          >
            <option disabled value="">
              -- Topic --
            </option>
            {Object.values(topics).map((topic) => (
              <option key={topic.id} value={topic.id}>
                {topic.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Button
          type="submit"
          variant="primary"
          size="lg"
          disabled={!content.trim() || !topicId}
        >
          Ask
        </Button>
      </Form>
    </Container>
  );
}
