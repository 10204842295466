import { toast } from "react-hot-toast";
import { io } from "socket.io-client";
import store from "../state/store";
import { IIssue, IIssueMessage, IUser } from "../types";
import { getCurrentUser, getIssues, getUser } from "./api";
import {
  normalizeAndStoreIssue,
  normalizeAndStoreMessage,
  toNormalizedStore,
} from "./normalize";

const socket = io(process.env.REACT_APP_GATEWAY_URL as string, {
  withCredentials: true,
});

socket.on("connect", () => {
  store.dispatch({ type: "SET_CONNECTED", payload: true });
  getCurrentUser().catch(() => {});
  console.log("[WS] Successfully connected to server.");
});

socket.on("disconnect", () => {
  store.dispatch({ type: "SET_CONNECTED", payload: false });
  store.dispatch({ type: "RESET_ISSUES" });
  getCurrentUser().catch(() => {});
  console.log("[WS] Disconnected from server.");
});

socket.on("update_user_subscribe_topic", async (topicId: string) => {
  store.dispatch({
    type: "SET_SUBSCRIBED",
    payload: new Set([
      ...Array.from(store.getState().topics.subscribed),
      topicId,
    ]),
  });
  await getIssues(void 0, store.getState().issues.showClosed);
});

socket.on("update_user_unsubscribe_topic", (topicId: string) => {
  store.dispatch({
    type: "SET_SUBSCRIBED",
    payload: new Set(
      Array.from(store.getState().topics.subscribed).filter(
        (topic) => topic !== topicId
      )
    ),
  });
  store.dispatch({
    type: "SET_ISSUES",
    payload: toNormalizedStore(
      Object.values(store.getState().issues.issues).filter(
        (issue) => issue.topicId !== topicId
      )
    ),
  });
});

socket.on("create_issue", async (issue: IIssue) => {
  if (
    !(issue.id in store.getState().issues.issues) &&
    issue.authorId !== store.getState().gatewayStatus.user
  ) {
    let user: IUser | null = store.getState().users.users[issue.authorId];
    if (!user) user = await getUser(void 0, issue.authorId);
    toast(
      (t) => (
        <div className="text-center">
          <b>New question by {user?.username}</b>
          <br />
          <span>{issue.title}</span>
        </div>
      ),
      { icon: "📨", duration: 10000 }
    );
  }
  normalizeAndStoreIssue(issue);
});

socket.on("update_issue", (issue: IIssue) => {
  normalizeAndStoreIssue(issue);
});

socket.on("update_issue_message", (message: IIssueMessage) => {
  normalizeAndStoreMessage(message);
});

export const connect = () => {
  if (!socket.connected) {
    socket.disconnect();
    socket.connect();
  }
};

export const disconnect = () => {
  if (socket.connected) socket.disconnect();
};

export const subscribeIssue = (issueId: string) => {
  socket.emit("subscribe_issue", issueId);
};

export const unsubscribeIssue = (issueId: string) => {
  socket.emit("unsubscribe_issue", issueId);
};
