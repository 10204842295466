export interface GatewayStatusState {
  loaded: boolean;
  connected: boolean;
  user: string | undefined;
}

export const initialState: GatewayStatusState = {
  loaded: false,
  connected: false,
  user: void 0,
};

type Action =
  | { type: "SET_CONNECTED"; payload: boolean }
  | { type: "SET_CURRENT_USER"; payload: string | undefined };

export default function gatewayStatusReducer(
  state = initialState,
  action: Action
): GatewayStatusState {
  switch (action.type) {
    case "SET_CONNECTED":
      return {
        ...state,
        connected: action.payload,
      };
    case "SET_CURRENT_USER":
      return {
        ...state,
        loaded: true,
        user: action.payload,
      };
    default:
      return state;
  }
}
