import React from "react";
import { useSelector } from "react-redux";
import { useUser } from "../../api/stateKeeper";
import { GatewayStatusState } from "../../state/gatewayStatusReducer";
import { RootState } from "../../state/store";
import { IIssueMessage } from "../../types";
import AgoTime from "./AgoTime";

interface IChatMessageProps {
  message: IIssueMessage;
}

export default function ChatMessage(props: IChatMessageProps) {
  const { message } = props;

  const userId = useSelector<RootState, GatewayStatusState["user"]>(
    (state) => state.gatewayStatus.user
  );

  const author = useUser(message.authorId);

  const isCurrentUser = message.authorId === userId;

  // TODO: Use correct time
  return isCurrentUser ? (
    <li className="chat-right">
      <div className="chat-hour">
        <AgoTime date={message.createdAt} />
        <span className="fa fa-check-circle"></span>
      </div>
      <div className="chat-text">{message.content}</div>
      <div className="chat-avatar">
        <img
          src={`https://picsum.photos/seed/${message.authorId}/512/512`}
          alt={author?.username || "Loading..."}
        />
        <div className="chat-name">{author?.username || "Loading..."}</div>
      </div>
    </li>
  ) : (
    <li className="chat-left">
      <div className="chat-avatar">
        <img
          src={`https://picsum.photos/seed/${message.authorId}/512/512`}
          alt={author?.username || "Loading..."}
        />
        <div className="chat-name">{author?.username || "Loading..."}</div>
      </div>
      <div className="chat-text">{message.content}</div>
      <div className="chat-hour">
        <AgoTime date={message.createdAt} />
        <span className="fa fa-check-circle"></span>
      </div>
    </li>
  );
}
