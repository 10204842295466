import React from "react";
import Badge from "react-bootstrap/Badge";
import { useTopic } from "../../api/stateKeeper";
import { IIssue } from "../../types";

interface IIssueBadgeProps {
  issue: IIssue | undefined;
}

export default function IssueBadges(props: IIssueBadgeProps) {
  const { issue } = props;

  const topic = useTopic(issue?.topicId);

  return (
    <>
      <Badge variant="secondary">{topic?.name}</Badge>
      {issue?.closed && (
        <>
          {" "}
          <Badge variant="danger">Closed</Badge>
        </>
      )}
    </>
  );
}
