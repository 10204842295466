import { combineReducers, createStore } from "redux";
import gatewayStatusReducer from "./gatewayStatusReducer";
import issuesReducer from "./issuesReducer";
import topicsReducer from "./topicsReducer";
import usersReducer from "./usersReducer";

const rootReducer = combineReducers({
  gatewayStatus: gatewayStatusReducer,
  topics: topicsReducer,
  issues: issuesReducer,
  users: usersReducer,
});

export type NormalizedStore<T> = { [id: string]: T };

export type RootState = ReturnType<typeof rootReducer>;

export default createStore(rootReducer);
