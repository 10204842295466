import React from "react";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import { toast } from 'react-hot-toast';
import { LinkContainer } from "react-router-bootstrap";
import { useHistory } from "react-router-dom";
import { assignIssue } from "../../api/api";
import { useCurrentUser, useUser } from "../../api/stateKeeper";
import { IIssue } from "../../types";
import AgoTime from "./AgoTime";
import IssueBadges from "./IssueBadges";

interface IListIssueProps {
  issue: IIssue;
}

export default function ListIssue(props: IListIssueProps) {
  const { issue } = props;

  const history = useHistory();

  const user = useCurrentUser();
  const author = useUser(issue.authorId);

  const assignable = !issue.closed && user?.teacher && !issue.teacherId;

  const handleAssignIssue = async (issueId: string) => {
    await assignIssue(history, issueId);
    history.push(`/issues/${issueId}`);
    toast.success("Question assigned!");
  };

  return (
    <ListGroup.Item
      key={issue.id}
      className="justify-content-between d-flex w-100"
    >
      <div>
        <p className="mb-0">
          {issue?.title} <IssueBadges issue={issue} />
        </p>
        <small>
          Created by {author?.username}{" "}
          <AgoTime date={issue.createdAt} inline />
        </small>
      </div>
      <div>
        <LinkContainer to={`/issues/${issue.id}`}>
          <Button
            variant={assignable ? "secondary" : "primary"}
            onClick={assignable ? () => handleAssignIssue(issue.id) : void 0}
          >
            {assignable ? "Assign" : "View"}
          </Button>
        </LinkContainer>
      </div>
    </ListGroup.Item>
  );
}
