import { IUser } from "../types";
import { NormalizedStore } from "./store";

export interface UsersState {
  users: NormalizedStore<IUser>;
}

export const initialState: UsersState = {
  users: {},
};

type Action = { type: "UPDATE_USERS"; payload: NormalizedStore<IUser> };

export default function usersReducer(
  state = initialState,
  action: Action
): UsersState {
  switch (action.type) {
    case "UPDATE_USERS":
      return {
        ...state,
        users: { ...state.users, ...action.payload },
      };
    default:
      return state;
  }
}
