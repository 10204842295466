import { normalize, schema } from "normalizr";
import store, { NormalizedStore } from "../state/store";
import { IIssue, IIssueMessage, ITopic, IUser } from "../types";
import { APIModel, buildModel, isModelValid } from "./apiBase";

// Define a topic schema
const topicSchema = new schema.Entity<ITopic>("topics");

// Define a users schema
const userSchema = new schema.Entity<IUser>("users", {
  topics: [topicSchema],
});

// Define a issue schema
const issueSchema = new schema.Entity<IIssue>("issues");

// Define a message schema
const messageSchema = new schema.Entity<IIssueMessage>("messages");

/**
 * Map the data with the api’s buildModel function.
 * @param state The unmapped store data.
 */
const mapNormalizedStoreToModel = <T extends APIModel<T>>(
  state: NormalizedStore<T>
) => {
  for (const id of Object.keys(state)) {
    state[id] = buildModel(state[id]);
    if (!isModelValid(state[id])) delete state[id];
  }
  return state;
};

/**
 * Convert an array to a normalized store.
 * @param array The array.
 */
export const toNormalizedStore = <T extends APIModel<T>>(
  array: Array<T>
): NormalizedStore<T> =>
  array.reduce(
    (store, current) => ({
      ...store,
      [current.id]: current,
    }),
    {}
  );

/**
 * Normalize and store the current user.
 * @param userData The unnormalized data.
 */
export const normalizeAndStoreCurrentUser = (userData: IUser) => {
  const normalizedData = normalize(userData, userSchema);

  const users = mapNormalizedStoreToModel(
    (normalizedData.entities["users"] || {}) as NormalizedStore<IUser>
  );
  const topics = mapNormalizedStoreToModel(
    ((normalizedData.entities["topics"] ||
      {}) as unknown) as NormalizedStore<ITopic>
  );

  const currentUserId = normalizedData.result;
  const currentUser = users[currentUserId];

  store.dispatch({ type: "UPDATE_USERS", payload: users });
  store.dispatch({
    type: "UPDATE_TOPICS",
    payload: topics,
  });
  store.dispatch({
    type: "SET_CURRENT_USER",
    payload: currentUserId,
  });
  store.dispatch({
    type: "SET_SUBSCRIBED",
    payload: new Set(Object.keys(topics)),
  });

  return currentUser;
};

/**
 * Normalize and store users.
 * @param usersData The unnormalized data.
 */
export const normalizeAndStoreUsers = (usersData: Array<IUser>) => {
  const normalizedData = normalize(usersData, [userSchema]);

  const users = mapNormalizedStoreToModel(
    (normalizedData.entities["users"] || {}) as NormalizedStore<IUser>
  );

  store.dispatch({
    type: "UPDATE_USERS",
    payload: users,
  });

  return users;
};

/**
 * Normalize and store a user.
 * @param userData The unnormalized data.
 */
export const normalizeAndStoreUser = (userData: IUser): IUser | null => {
  return normalizeAndStoreUsers([userData])[userData.id] || null;
};

/**
 * Normalize and store topics.
 * @param topicsData The unnormalized data.
 */
export const normalizeAndStoreTopics = (topicsData: Array<ITopic>) => {
  const normalizedData = normalize(topicsData, [topicSchema]);

  const topics = mapNormalizedStoreToModel(
    (normalizedData.entities["topics"] || {}) as NormalizedStore<ITopic>
  );

  store.dispatch({
    type: "UPDATE_TOPICS",
    payload: topics,
  });

  return topics;
};

/**
 * Normalize and store a topic.
 * @param topicData The unnormalized data.
 */
export const normalizeAndStoreTopic = (topicData: ITopic): ITopic | null => {
  return normalizeAndStoreTopics([topicData])[topicData.id] || null;
};

/**
 * Normalize and store issues.
 * @param issuesData The unnormalized data.
 */
export const normalizeAndStoreIssues = (issuesData: Array<IIssue>) => {
  const normalizedData = normalize(issuesData, [issueSchema]);

  const issues = mapNormalizedStoreToModel(
    (normalizedData.entities["issues"] || {}) as NormalizedStore<IIssue>
  );

  store.dispatch({
    type: "UPDATE_ISSUES",
    payload: issues,
  });

  return issues;
};

/**
 * Normalize and store an issue.
 * @param issueData The unnormalized data.
 */
export const normalizeAndStoreIssue = (issueData: IIssue): IIssue | null => {
  return normalizeAndStoreIssues([issueData])[issueData.id] || null;
};

/**
 * Normalize and store messages.
 * @param messagesData The unnormalized data.
 */
export const normalizeAndStoreMessages = (
  messagesData: Array<IIssueMessage>
) => {
  const normalizedData = normalize(messagesData, [messageSchema]);

  const messages = mapNormalizedStoreToModel(
    (normalizedData.entities["messages"] ||
      {}) as NormalizedStore<IIssueMessage>
  );

  store.dispatch({
    type: "UPDATE_ISSUE_MESSAGES",
    payload: messages,
  });

  return messages;
};

/**
 * Normalize and store a message.
 * @param messageData The unnormalized data.
 */
export const normalizeAndStoreMessage = (
  messageData: IIssueMessage
): IIssueMessage | null => {
  return normalizeAndStoreMessages([messageData])[messageData.id] || null;
};
