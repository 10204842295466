import { ITopic } from "../types";
import { NormalizedStore } from "./store";

export interface TopicsState {
  topics: NormalizedStore<ITopic>;
  subscribed: Set<string>;
}

export const initialState: TopicsState = {
  topics: {},
  subscribed: new Set(),
};

type Action =
  | { type: "UPDATE_TOPICS"; payload: NormalizedStore<ITopic> }
  | { type: "SET_SUBSCRIBED"; payload: Set<string> };

export default function topicsReducer(
  state = initialState,
  action: Action
): TopicsState {
  switch (action.type) {
    case "UPDATE_TOPICS":
      return {
        ...state,
        topics: { ...state.topics, ...action.payload },
      };
    case "SET_SUBSCRIBED":
      return {
        ...state,
        subscribed: action.payload,
      };
    default:
      return state;
  }
}
