import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getCurrentUser } from "../../api/api";
import { GatewayStatusState } from "../../state/gatewayStatusReducer";
import { RootState } from "../../state/store";
import Loading from "./Loading";

export function useLoaded() {
  const [splash, setSplash] = useState(true);

  useEffect(() => void setTimeout(() => setSplash(false), 1000), []);

  const gatewayStatusLoaded = useSelector<
    RootState,
    GatewayStatusState["loaded"]
  >((state) => state.gatewayStatus.loaded);

  const connected = useSelector<RootState, GatewayStatusState["connected"]>(
    (state) => state.gatewayStatus.connected
  );

  useEffect(() => void getCurrentUser(), [connected]);

  const loaded = !splash && gatewayStatusLoaded;
  return loaded;
}

export default function ProtectedArea(props: React.PropsWithChildren<{}>) {
  const { children } = props;

  const history = useHistory();

  const loaded = useLoaded();

  const userId = useSelector<RootState, GatewayStatusState["user"]>(
    (state) => state.gatewayStatus.user
  );

  useEffect(() => {
    if (loaded && !userId && history.location.pathname !== "/login")
      history.push("/login");
  }, [loaded, userId, history]);

  const className = loaded ? "loaded" : "loading";

  return (
    <>
      <div
        className={`protected-content flex-grow-1 d-flex flex-column ${className}`}
      >
        {children}
      </div>
      <div className={`loading-page ${className}`}>
        <Loading />
      </div>
    </>
  );
}
