import React, { FormEvent, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { login } from "../api/api";
import "../assets/style/login.scss";
import { GatewayStatusState } from "../state/gatewayStatusReducer";
import { RootState } from "../state/store";

export default function Login() {
  const history = useHistory();

  const connected = useSelector<RootState, GatewayStatusState["connected"]>(
    (state) => state.gatewayStatus.connected
  );

  useEffect(() => {
    if (!connected && history.location.pathname !== "/login")
      history.push("/login");
    if (connected) history.push("/");
  }, [history, connected]);

  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      await login(history, user, password);
    } catch (e) {
      setErrorMessage(e.message);
    }
  };

  return (
    <Container className="login-container py-3 pt-5">
      <Row className="row justify-content-center">
        <Col className="login-box" md={8}>
          <h1 className="text-center">Login</h1>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="loginUser">
              <Form.Label>User</Form.Label>
              <Form.Control
                type="text"
                size="lg"
                value={user}
                onChange={(e) => setUser(e.target.value)}
                isInvalid={Boolean(errorMessage)}
              />
              <Form.Control.Feedback type="invalid">
                {errorMessage}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="loginPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                size="lg"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                isInvalid={Boolean(errorMessage)}
              />
              <Form.Control.Feedback type="invalid">
                {errorMessage}
              </Form.Control.Feedback>
            </Form.Group>
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={!user.trim() || !password.trim()}
            >
              Login
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
