import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  closeIssue,
  getIssue,
  getIssueMessages,
  getTopic,
  getUser,
  sendIssueMessage,
} from "../api/api";
import { subscribeIssue, unsubscribeIssue } from "../api/gateway";
import { IssuesState } from "../state/issuesReducer";
import { RootState } from "../state/store";
import { UsersState } from "../state/usersReducer";
import ChatTextBox from "./partials/ChatTextBox";
import ChatView from "./partials/ChatView";
import IssueOverview from "./partials/IssueOverview";

let closing = false;

export default function ViewIssue() {
  const history = useHistory();
  const { issueId } = useParams<{ issueId: string }>();

  useEffect(() => {
    getIssue(history, issueId).then(
      (issue) =>
        issue &&
        getTopic(history, issue.topicId) &&
        getIssueMessages(history, issueId)
    );
    subscribeIssue(issueId);
    return () => unsubscribeIssue(issueId);
  }, [history, issueId]);

  const issue = useSelector<RootState, IssuesState["issues"][0] | undefined>(
    (state) => state.issues.issues[issueId]
  );

  // TODO: Make sure issues are ordered correctly
  const messages = useSelector<RootState, Array<IssuesState["messages"][0]>>(
    (state) =>
      Object.values(state.issues.messages).filter(
        (message) => message.issueId === issue?.id
      )
  );

  const users = useSelector<RootState, UsersState["users"]>(
    (state) => state.users.users
  );

  useEffect(() => {
    const authorIds = Array.from(
      new Set(messages.map((message) => message.authorId))
    );
    for (const authorId of authorIds) {
      const author = users[authorId];
      if (!author) getUser(history, authorId);
    }
  }, [history, messages, users]);

  const handleSend = (content: string) =>
    sendIssueMessage(history, issueId, content);

  const [showCloseModal, setShowCloseModal] = useState(false);

  const handleClose = () => {
    if (closing) return;
    closing = true;

    closeIssue(history, issueId).finally(() => {
      closing = false;
      setShowCloseModal(false);
    });
  };

  return (
    <>
      <IssueOverview issue={issue} />
      <main className="py-3 pt-5 flex-grow-1">
        <Container>
          <ChatView messages={messages} />
        </Container>
      </main>
      <ChatTextBox
        closed={Boolean(issue?.closed)}
        handleSend={handleSend}
        handleClose={() => setShowCloseModal(true)}
      />

      <Modal show={showCloseModal} onHide={() => setShowCloseModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Once you close this question, you will not be able to post any new
          messages or reopen it.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCloseModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleClose}>
            Close Question
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
